import React from 'react';
import './index.less';
import { Table, Image, Button } from 'antd';


export default function (props) {
    const {columns, dataSource} = props;
    let tableColumns = [];

    columns.forEach((item) => {
        if (item.type) {
            tableColumns.push({
                title: item.title,
                dataIndex: item.dataIndex,
                key: item.key,
                render: (value, rowData) => {
                    return (
                        <div>
                            <Choose>
                                <When condition={item.type === 'image'}>
                                    <Image
                                        src={rowData[item.dataIndex]}
                                        width="32px"
                                        height="32px"
                                    />
                                </When>
                                <When condition={item.type === 'operator'}>
                                    {item.buttons.map(button => {
                                        return (
                                            <Button
                                                type="link"
                                                onClick={(e) => {
                                                    button.onClick(rowData, e);
                                                }}
                                            >
                                                {button.title}
                                            </Button>
                                        );
                                    })}
                                </When>
                            </Choose>
                        </div>
                    );
                }
            });
        }
        else {
            tableColumns.push(item);
        }
    })

    return (
        <Table
            dataSource={dataSource}
            columns={tableColumns}
        />
    );
}