import React from "react";
import "./index.less";
import Login from "../login";
import { useSelector } from "react-redux";
import {
    LaptopOutlined,
    NotificationOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme, MenuProps } from "antd";
import useHomeHooks from './hooks';
import LunboManage from "../LunboManage";

const { Header, Content, Sider } = Layout;

export default function (props) {
    let loginData = useSelector((state) => state.login);
    let layoutData = useSelector(state => state.layout);
    let homeHooks = useHomeHooks();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <div className="home_page">
            <If condition={!loginData.isLogin}>
                <Login />
            </If>
            <If condition={loginData.isLogin}>
                <Layout>
                    {/* <Header
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div className="demo-logo" />
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={["2"]}
                            items={[{key: 'home', 'label': 'home'}]}
                            style={{
                                flex: 1,
                                minWidth: 0,
                            }}
                        />
                    </Header> */}
                    <Layout>
                        <Sider
                            width={200}
                            style={{
                                background: colorBgContainer,
                            }}
                        >
                            <Menu
                                mode="inline"
                                style={{
                                    height: "100%",
                                    borderRight: 0,
                                }}
                                items={layoutData.sideMenu}
                                onClick={homeHooks.clickMenuCallback}
                                defaultSelectedKeys={['lunbo']}
                                defaultOpenKeys={['baseOpt']}
                            />
                        </Sider>
                        <Layout
                            style={{
                                padding: "0 24px 24px",
                            }}
                        >
                            <Breadcrumb
                                style={{
                                    margin: "16px 0",
                                }}
                            >
                                {
                                    layoutData.menuPath.map((item) => {
                                        return (
                                            <Breadcrumb.Item>
                                                {item.label}
                                            </Breadcrumb.Item>
                                        );
                                    })
                                }
                            </Breadcrumb>
                            <Content
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                    background: colorBgContainer,
                                    borderRadius: borderRadiusLG,
                                }}
                            >
                                <Choose>
                                    <When condition={layoutData.currentMenu === 'lunbo'}>
                                        <LunboManage />
                                    </When>
                                </Choose>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </If>
        </div>
    );
}
