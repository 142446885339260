import React from 'react';
import ReactDom from 'react-dom';
import {Provider} from 'react-redux';
import 'antd/dist/reset.css';
import './App.less';
// import Login from './page/login';
import {store} from './store/store';
import Home from './page/home';


export default function() {
    return (
        <Provider store={store}>
            <div className="xx_system_page">
                <Home />
            </div>
        </Provider>
    );
}