// src/redux/store.js  
import { configureStore } from '@reduxjs/toolkit';  
import {login} from './reducers/login'; // 假设你有一个counterSlice 
import {layout} from './reducers/layout'; 

export const store = configureStore({  
  reducer: {  
    login: login.reducer,  
    layout: layout.reducer
  },  
});