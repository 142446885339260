import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {updateCurrentMenu, updateSideMenu} from "../../store/reducers/layout";
import axios from 'axios';

export default function useHome() {

    let layoutData = useSelector(state => state.layout);
    let dispatch = useDispatch();

    function getMenuList() {
        axios.get('/api/getMenuList')
            .then((res) => {
                if (res.data.status === 'success') {
                    let list = res.data.list;
                    let menu = [];
                    let menuKeyMap = {};

                    list.forEach((item) => {
                        if (!menuKeyMap[item.category_key]) {
                            menuKeyMap[item.category_key] = menu.length;
                        }

                        if (menu[menuKeyMap[item.category_key]] !== undefined) {
                            menu[menuKeyMap[item.category_key]] = {
                                key: item.category_key,
                                label: item.category_name,
                                children: []
                            }
                        }

                        menu[menuKeyMap[item.category_key]].children.push({
                            key: item.menu_key,
                            label: item.menu_name
                        });
                    });
 

                    dispatch(updateSideMenu(menu));
                }
            });
    }

    useEffect(() => {
        getMenuList();
    }, []);

    return {
        clickMenuCallback(data) {
            dispatch(updateCurrentMenu({
                key: data.key,
                keyPath: data.keyPath
            }));
        }
    }
}