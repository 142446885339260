import React from 'react';
import './index.less';
import {Input, Button} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {changeName, changePassword} from '../../store/reducers/login';
import useLoginHooks from './hooks';

export default function(props) {

    const data = useSelector((state) => ({
        name: state.name,
        password: state.password
    }));
    const dispatch = useDispatch();
    const func = useLoginHooks();

    return (
        <div className="mini_team_login_page">
            <div className="login_page_content">
                <div className="login-title">
                    用户登录
                </div>
                <Input
                    size="large"
                    placeholder="请输入用户名"
                    value={data.name}
                    onChange={e => {
                        dispatch(changeName(e.target.value))
                    }}
                />
                <Input.Password
                    size="large"
                    placeholder="请输入密码"
                    value={data.password}
                    onChange={e => {
                        dispatch(changePassword(e.target.value));
                    }}
                />
                <div className="login-buttons">
                    <Button
                        block
                        type="primary"
                        size="large"
                        onClick={func.postLogin}
                    >
                        登录
                    </Button>
                </div>
                <div className="login-page-user-register">
                    <Button type="link">忘记密码</Button>
                    <Button type="link">注册用户</Button>
                </div>
            </div>
            {func.contextHolder}
        </div>
    );
}