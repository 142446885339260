import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';

export default function useLunbo() {

    let [imgList, setImgList] = useState([]);


    function updateImgList() {
        axios.get('/api/getLunboImageList')
            .then((res) => {
                if (res.data.status === 'success') {
                    setImgList(res.data.data);
                }
            });
    }

    function changeFileList({file, fileList}) {
        setImgList([...fileList]);
    }

    function removeImage(item) {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: '确定要删除吗?',
                onOk: () => {
                    axios.post('/api/deleteLunboImage', {
                        data: item
                    })
                        .then(() => {
                            updateImgList();
                            resolve();
                        });
                }
            });   
        });
    }

    useEffect(() => {
        updateImgList();
    }, []);

    return {
        updateImgList,
        imgList,
        changeFileList,
        removeImage
    };
}