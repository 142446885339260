// 引入React和ReactDOM  
import React from 'react';  
import ReactDOM from 'react-dom';  
  
// 引入App组件（假设你有一个名为App的React组件）  
import App from './App';  
  
// ReactDOM.render 方法用于将React元素渲染到DOM中  
// 第一个参数是你想要渲染的React元素（在这个例子中是<App />组件）  
// 第二个参数是一个DOM元素，表示你想将React元素渲染到这个DOM元素内部  
// 在这个例子中，我们假设HTML中有一个id为'root'的div元素  
ReactDOM.render(  
  <React.StrictMode>  
    <App />  
  </React.StrictMode>,  
  document.getElementById('root')  
);  
  
// React.StrictMode是一个帮助识别应用中不安全代码的组件  
// 它不会修改任何运行时的行为，但会帮助你在开发模式下捕获到潜在的问题  
// 你可以根据需要选择是否使用React.StrictMode