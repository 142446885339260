import {createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const login = createSlice({
    name: 'login',
    initialState: {
        name: '',
        password: '',
        isLogin: !!Cookies.get('EGG_SESS')
    },
    reducers: {
        changeName: (state, action) => {
            state.name = action.payload
        },
        changePassword: (state, action) => {
            state.password = action.payload
        },
        updateLoginState: (state, action) => {
            state.isLogin = action.payload;
        }
    },
});

export const {changeName, changePassword, updateLoginState} = login.actions;

