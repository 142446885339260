import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { changeName, changePassword, updateLoginState } from "../../store/reducers/login";
import { message } from "antd";

export default function useLoginHooks() {
    let data = useSelector((state) => state);
    let [messageApi, contextHolder] = message.useMessage();
    let dispatch = useDispatch();

    return {
        contextHolder: contextHolder,
        postLogin: () => {
            axios
                .post("/api/login", {
                    data: data.login,
                })
                .then((res) => {
                    if (res.data.success) {
                        messageApi.open({
                            type: "success",
                            content: res.data.message,
                        });
                        dispatch(updateLoginState(true));
                    } else {
                        messageApi.open({
                            type: "error",
                            content: res.data.message,
                        });
                    }
                })
                .catch((e) => {
                    messageApi.open({
                        type: "error",
                        content: e.message,
                    });
                });
        },
    };
}
