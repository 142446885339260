import { createSlice } from "@reduxjs/toolkit";

export const layout = createSlice({
    name: "layout",
    initialState: {
        sideMenu: [{
            key: "baseOpt",
            icon: "",
            label: "基础建设",
            children: [{
                key: 'manageMenu',
                label: '菜单管理'
            }, {
                key: "lunbo",
                label: "轮播图管理"
            }, {
                key: "contentMange",
                label: "内容管理"
            }]
        }],
        currentMenu: "lunbo",
        menuPath: [{ label: '基础操作' }, { label: '轮播图管理' }],
    },
    reducers: {
        updateSideMenu(state, action) {
            state.sideMenu = action.payload;
        },
        updateCurrentMenu(state, action) {
            let menuList = [...state.sideMenu];
            let keyPath = [...action.payload.keyPath];
            let path = [];

            while (menuList && menuList.length > 0) {
                let current = menuList.pop();

                keyPath.reverse().forEach((currentKey) => {
                    if (current.key === currentKey) {
                        path.push(current);
                        menuList = current.children
                            ? menuList.concat(current.children)
                            : null;
                    }
                });
            }

            state.menuPath = path;
            state.currentMenu = action.payload.key;
        },
    },
});

export const { updateSideMenu, updateCurrentMenu } = layout.actions;
