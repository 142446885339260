import React from 'react';
import './index.less';

import { Upload, Button, Table } from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import useLunbo from './hooks';
import CustomTable from '../../components/CustomTable';

export default function (props) {

    let {imgList, updateImgList, changeFileList, removeImage} = useLunbo();

    return (
        <div className="lunbo-manange-page">
            <Upload
                action="https://www.mayun888.top/api/uploadImage"
                listType="picture"
                fileList={imgList.map(item => {
                    return {
                        ...item,
                        uid: item.id,
                        name: item.title,
                        status: 'done',
                        url: item.link_url,
                        thumbUrl: item.link_url
                    }
                })}
                onChange={changeFileList}
                onRemove={removeImage}
                multiple={true}
                showUploadList={false}
            >
                <Button icon={<UploadOutlined />}>上传图片</Button>
            </Upload>
            <CustomTable
                columns={[{
                    title: '图片',
                    dataIndex: 'link_url',
                    key: 'link_url',
                    type: 'image'
                }, {
                    title: '图片编码',
                    dataIndex: 'id',
                    key: 'id'
                }, {
                    title: '图片名称',
                    dataIndex: 'title',
                    key: 'title'
                }, {
                    title: '操作',
                    dataIndex: 'operator',
                    key: 'operator',
                    type: 'operator',
                    buttons: [{
                        title: '删除',
                        key: 'delete',
                        onClick: removeImage
                    }]
                }]}
                showCheckbox={true}
                dataSource={imgList}
            />
        </div>
    );
}